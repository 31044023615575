import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { getKey } from '../helpers';

import BuscadorComponent from '../components/compound/Buscador';

/**
 * Buscador Component
 * @param {*} props - component props
 * @returns {JSX}
 */
const Buscador = props => {
  const metaTitle = getKey(props, 'pageContext.metaTitle');
  const contents = getKey(props, 'data.solutions.edges', [])
    .map(item => {
      const { node } = item;
      const { family, name } = node;

      return {
        ...node,
        family: {
          ...family,
          name: family.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        },
        name: name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      };
    });

  return (
    <>
      {metaTitle && (
        <Helmet>
          <html lang="es" />
        </Helmet>
      )}
      <BuscadorComponent contents={contents} />
    </>
  );
};

export default Buscador;

export const query = graphql`
  query BusacdorSoluciones {
    solutions: allStrapiSolutions(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          family {
            gradient {
              start
              end
            }
            name
          }
          name
          slug
        }
      }
    }
  }
`;
