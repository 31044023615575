import { css } from 'styled-components';
import {
  SHARK,
  WHITE,
  WOODSMOKE,
  GRADIENT_ORANGE_TO_VIOLET,
} from '../../../../constants/colors';
import { media, rem } from '../../../../helpers/mixins';

export default {
  closeButton: css`
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 1px;
    top: 1px;

    img {
      height: 18px;
      width: 18px;
    }

    ${media.md`
      top: 6px;
      right: 2px;

      img {
        height: 32px;
        width: 32px;
      }
    `}
  `,
  container: css`
    align-items: center;
    background-color: ${WOODSMOKE};
    color: ${WHITE};
    display: flex;
    height: 50vh;
    justify-content: center;
    padding: 0 20px;
    width: 100%;
  `,
  input: css`
    background: transparent;
    border: 0;
    color: ${WHITE};
    font-size: ${rem(16)};
    font-weight: 700;
    height: 100%;
    padding: 0 18px;
    width: 100%;

    ${media.md`
      font-size: ${rem(26)};
    `}

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${SHARK};
    }
  `,
  wrapper: css`
    background-clip: content-box, border-box;
    background-color: ${WOODSMOKE};
    background-image: linear-gradient(${WOODSMOKE}, ${WOODSMOKE}), ${GRADIENT_ORANGE_TO_VIOLET};
    background-origin: border-box;
    border: double 1px transparent;
    border-radius: 16px;
    height: 32px;
    position: relative;
    width: 100%;

    form {
      height: 100%;
    }

    ${media.md`
      border-radius: 24px;
      width: 480px;
      height: 48px;
    `};
  `,
};
