import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import closeIcon from '../../../../../static/images/close.svg';

import Styles from './SearchField.styles';
import { isValidFunction } from '../../../../helpers';

const Container = styled.div`
  ${Styles.container}
`;

const Input = styled.input`
  ${Styles.input}
`;

const Wrapper = styled.div`
  ${Styles.wrapper}
`;

const CloseButton = styled.button`
  ${Styles.closeButton}
`;

const resetEvent = {
  target: {
    value: '',
  }
};

/**
 * SearchField component
 * @param {Object} props - props for the input
 * @returns {JSX}
 */
const SearchField = props => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const formRef = useRef();
  const { onKeyUp } = props;

  /**
   * Handle form reset
   * @param {Object} e - event
   */
  const handleReset = e => {
    e.preventDefault();

    if (isValidFunction(onKeyUp)) onKeyUp(resetEvent);
    formRef.current.reset();
    formRef.current.query.focus();
    setShowCloseButton(false);
  };

  /**
   * Handle input change
   * @param {Object} e - event
   */
  const handleChange = e => {
    setShowCloseButton(e.target.value.length > 0);
  };

  return (
    <Container>
      <Wrapper>
        <form ref={formRef} autoComplete="off">
          <Input
            type="text"
            autocomplete="false"
            placeholder="Buscador de Soluciones"
            name="query"
            onChange={handleChange}
            {...props}
          />
          {
            showCloseButton && (
              <CloseButton onClick={handleReset}>
                <img src={closeIcon} alt="" />
              </CloseButton>
            )
          }
        </form>
      </Wrapper>
    </Container>
  );
};

SearchField.propTypes = {
  onKeyUp: PropTypes.func,
};

export default SearchField;
