import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SearchField from './SearchField';
import Results from './Results';

const MIN_LENGTH = 3;

/**
 * Buscador Component
 * @returns {JSX}
 */
const Buscador = ({
  contents
}) => {
  const [matches, setMatches] = useState([]);
  /**
   * OnKeyUp handler
   * @param {Object} e - event handler
   */
  const handleOnKeyUp = e => {
    const { value } = e.target;

    if (value.length >= MIN_LENGTH) {
      const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const regexPattern = `\\b${normalizedValue}\\b`;
      const re = new RegExp(regexPattern, 'ig');
      const resultados = contents.filter(item => {
        const { family, name } = item;
        const { name: familyName } = family;

        return name.match(re) || familyName.match(re);
      });

      setMatches(resultados);
    } else {
      setMatches([]);
    }
  };

  return (
    <>
      <SearchField
        onKeyUp={handleOnKeyUp}
      />
      <Results matches={matches} />
    </>
  );
};

Buscador.propTypes = {
  contents: PropTypes.array,
};

export default Buscador;
