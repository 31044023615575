import { css, keyframes } from 'styled-components';
import { SHARK, WHITE } from '../../../../constants/colors';
import { media, rem } from '../../../../helpers/mixins';

const itemAnimation = keyframes`
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export default {
  container: css`
    background-color: ${SHARK};
    color: ${WHITE};
    min-height: 50vh;
    padding: 45px 0;
    width: 100%;
  `,
  headline: css`
    font-size: ${rem(24)};
    margin-bottom: 45px;

    ${media.md`
      font-size: ${rem(42)};
      margin-bottom: 90px;
    `}
  `,
  item: css`
    animation: ${itemAnimation} 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    margin-bottom: 30px;
    margin-left: calc(2/12 * 100%);

    ${media.md`
      margin-left: 0;
    `};
  `,
};
