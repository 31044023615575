import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isValidArray } from '../../../../helpers';

import Styles from './Results.styles';
import SolutionItem from '../../SolutionsCard/SolutionItem';

const Container = styled.div`
  ${Styles.container}
`;

const Item = styled.div`
  ${Styles.item}
`;

const Headline = styled.h1`
  ${Styles.headline}
`;

/**
 * Results component
 * @returns {JSX}
 */
const Results = ({
  matches,
}) => {
  const renderMatches = useMemo(() => {
    if (!isValidArray(matches)) return null;

    const items = matches.map(item => (
      <Item className="col-6 col-md-3" key={`result-${item.slug}`}>
        <SolutionItem
          color={item.family.gradient.start}
          gradient={item.family.gradient}
          location={{ pathname: '' }}
          {...item}
        />
      </Item>
    ));

    return (
      <div className="row">
        {items}
      </div>
    );
  }, [matches]);

  return (
    <Container>
      <div className="container">
        <Headline>Soluciones</Headline>

        {renderMatches}
      </div>
    </Container>
  );
};

Results.propTypes = {
  matches: PropTypes.array,
};

export default Results;
